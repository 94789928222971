.App {
  color: white;
  position: fixed;
  top: 50%;
  left: 50%;
}

.message {
  font-size: 12vw;
  white-space: nowrap;
  transform: translate(-50%, -50%);
}

.longMessage {
  font-size: 6vw;
  transform: translate(-50%, -40%);
  text-align: center;
}

.noEntryText {
  font-size: 3vw;
  white-space: nowrap;
  transform: translate(-50%, -50%);
  text-align: center;
  position: relative;
  top: -100px;
}

.green {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  background:#2B9341;
}

.red {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  background: #6C0000;
}

.topleft {
  font-size: 4vw;
  display: flex;
  margin: 0.5em;
  align-items: center;
  flex-direction: row-reverse;
}

.logo {
  height: 0.85em;
  margin-right: 0.5em;
}